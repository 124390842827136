import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Project from "./Project";

function ProjectList({projects}){
    return (
        <Grid 
            sx={{marginTop: "2rem"}}
            item 
            xs={12}
            id="work"
        >
            <Typography
                variant="h3"
                component="h2"
                textAlign="center"
            >
                Work
            </Typography>
            {projects.map((project) => <Project key={project.name} {...project}/>)}
        </Grid>
    );
}

export default ProjectList;